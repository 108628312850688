<template>
    <div class="text-center" style="padding-top: 40px;">
        <p><van-icon name="success" size="60" color="green" /></p>
        <h3 style="color: #2aabd2;margin-bottom: 20px;">参与成功!</h3>
        <p>您的兑奖码是:<span style="font-size: 20px;margin-left: 10px;color: #eab976;font-weight: bold;">{{participate.code}}</span></p>
        <h5 style="margin-bottom: 20px;">温馨提示：中奖后会给您推送微信通知，请注意查看！</h5>
        <div>
            <van-button round style="height: 40px;font-size: 14px;padding: 0 50px;"
                        @click="$router.replace('/lottery')">返回首页</van-button>
        </div>
    </div>
</template>

<script>
    import {Dialog} from 'vant';
    export default {
        name: "LotterySuccess",
        data(){
            return{
                participate:{
                    participate_id:0,
                    code:'',
                }
            }
        },
        mounted(){
            this.participate = this.$route.query;
            Dialog.alert({
                title: '报名成功',
                message: '您的兑奖码是:'+this.participate.code,
            });
        }
    }
</script>

<style scoped>
    .text-center{
        text-align: center;
    }
</style>